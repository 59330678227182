.spinner_custom {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  background: rgba(15, 12, 41, 0.5);
  z-index: 5000;
}
