.card {
    border-radius: 15px;}
.footer-align {
    justify-content: space-between;
    display: flex;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.ReactTable {
    border-radius: 10px;
}
.sidebar-nav ul .sidebar-item .sidebar-link i {
    font-style: normal;
    width: 35px;
    line-height: 25px;
    font-size: 25px;
    color: #fff;
    display: inline-block;
    text-align: center;
}
.sidebar-nav ul .sidebar-item .sidebar-link span {
    font-size: 20px;
}
.btn{
    border-radius: 10px;
}
.navbar-dark .navbar-brand {
    color: #fff;
}
.topbar .dropdown-menu {
    // padding-top: 0px;
    // border: 0px;
    // box-shadow: 1px 1px 15px rgb(0 0 0 / 10%);
    border-radius: 10px;
    background-color: aliceblue;
}
.drop-border-radius{
    // border-bottom-left-radius: 15px;
    // border-bottom-right-radius: 15px;
    border-radius: 10px;
}
.modal-content {
    border-radius: 10px;
}

