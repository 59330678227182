.alert-body{
    text-align: center;
}

.avatar-circle{
    padding: 5px 10px;
    background: #bdbdbd85;
    border-radius: 50%;
    left: -40px;
}
.height-10{
    height: 10px;
}
.login-cover{
    background-color: white;
    box-shadow: 2px 2px 5px grey, -2px -2px 5px grey;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 20px;
    height: 90vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    @media screen and (max-height: 700px) {
        height: auto;
    }
}
.toolbar-cover>button:nth-child(1){
    background-color: white;
}
.toolbar{
    margin-right: 0px;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.toolbar>div>a>button{
    width: 100%;
    height: 50px;
}
.toolbar>div>a>button>i{
    display: none;
    font-size: 25px;
}
.btn-sec-custom:hover i{
    display: block ;
}
.btn-sec-custom:hover span{
    display: none ;
}
.btn-sec-custom{
    border-color: white;
}
.btn-sec-custom:hover{
    border-color: greenyellow;
}
.btn-table-edit{
    height: 35px;
    width: 40px;
    background-color: #2962ff;
    border: none!important;
}
.btn-table-remove{
    height: 35px;
    width: 40px;
    background-color: #da1a1a;
    border: none!important;
}

.toolbar-cover>div.toolbar:nth-child(1)>div:nth-child(1)>a>button{   
    background-color: #0059b3;
}
.toolbar-cover>div.toolbar:nth-child(1)>div:nth-child(2)>a>button{   
    background-color:#1a8cff;
}
.toolbar-cover>div.toolbar:nth-child(1)>div:nth-child(3)>a>button{   
    background-color:#80bfff
}
.toolbar-cover>div.toolbar:nth-child(2)>div:nth-child(1)>a>button{   
    background-color: #0059b3;
}
.toolbar-cover>div.toolbar:nth-child(2)>div:nth-child(2)>a>button{   
    background-color:#1a8cff;
}
.toolbar-cover>div.toolbar:nth-child(2)>div:nth-child(3)>a>button{   
    background-color:#80bfff
}
.toolbar-cover>div.toolbar:nth-child(3)>div:nth-child(1)>a>button{   
    background-color: #0059b3;
}
.toolbar-cover>div.toolbar:nth-child(3)>div:nth-child(2)>a>button{   
    background-color:#1a8cff;
}
.toolbar-cover>div.toolbar:nth-child(3)>div:nth-child(3)>a>button{   
    background-color:#80bfff
}
.ReactTable .rt-th, .ReactTable .rt-td {
    
    align-items: center;
    display: flex;
}
.up-card-title{
    border-bottom: 1px solid #1a3ea0;
    padding: 25px 20px 10px;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}
.up-card-box{

    align-items: center;
}
.img-company{
    width: 180px;
    height: 180px;
}
.company-text-key{
    width: 190px;
    font-size: 17px;
    color: black;
}

.company-text-value{
    width: 190px;
    font-size: 14px;
    color: grey;
}
.company-rank-value{
    width: 80px;
    font-size: 14px;
    color: grey;
}

@media screen and (max-width: 600px) {
    .company-text-key{
        width: 150px;
        font-size: 17px;
        color: black;
    }
    .company-text-value{
        width: 150px;
        font-size: 14px;
        color: grey;
    }
    .company-rank-value{
        width: 60px;
        font-size: 14px;
        color: grey;
    }
  }
.company-text-area{
    margin-right: 0px;
    margin-left: 0px;
    padding:10px 10px;
}

.stopCompany{
    color: lightgrey;
}

.b-title{
    font-size: 26px;
    color: #1a3ea0;
    font-weight: 900;
}
.b-title-in{
    font-size: 15px;
}
.b-title-end{
    font-size: 15px;
    color: #1a3ea0;
    cursor: pointer;
}
.space-around{
    justify-content: space-around;
}
.login-logo{
    display: flex;
    align-items: center;
    background-color: #4fc3f7;
}
.ft-60{
    font-size: 60px;
}
.modal-header{
    padding-left: 40px;
}
.modal-btn{
    text-align: center;
    font-size: 20px;
}
.modal-btn button{
    width: 120px;
}
.in-avatar-cicle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.container-alert {
    // max-width: 208px;    /*adjust it*/
    // margin: auto;
    position: relative;
    // display: inline-block;
  }
  
  #milliseconds {
    padding-right: 35px;
  }
  
  .ms {
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .show-7btn-a{
    width: 14.2857%!important;
    flex: auto;
    @media screen and (max-width: 990px) {
        width: auto!important;
        flex: 0 0 25%;
      }
  }

  .show-7btn-b{
    width: 14.2857%!important;
    flex: auto;
    @media screen and (max-width: 990px) {
        width: 33.3333%!important;
      }
  }
