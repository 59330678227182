.tab-Table{
    width: 100%;
}
.nav-header-cus{
    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
        color: #525f7f;
        background-color: #fff;
        border-color: #1a3ea0 #dee2e6 #fff;
        border-top: 3px solid  #1a3ea0;
    }
}
