.alert-confirm{
    border: 1px solid lightgrey;
    padding: 5px;
    margin: 5px;
    border-radius: 8px;
    color: green;
    font-size: 15px;
}
.alert-missed {
    border: 1px solid lightgrey;
    padding: 5px;
    margin: 5px;
    border-radius: 8px;
    color: palevioletred;
    font-size: 15px;
}
.alert-remain {
    border: 1px solid lightgrey;
    padding: 5px;
    margin: 5px;
    border-radius: 8px;
    color: grey;
    font-size: 15px;
}
.duty-text-area{
    margin-right: 0px;
    margin-left: 15px;
    padding: 5px 10px;
}
.duty-text-area{
    .duty-text-key{
        width: 120px;
        font-size: 15px;
        color: black;
    }
    .duty-text-value{
        width: 120px;
        font-size: 14px;
        color: grey;
    }
}
.datepicker{
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid darkgrey;
}
.react-datepicker-wrapper{
    display: block;
}
.alertText{
    color: red;
    padding: 5px 30px;
    font-size: 18px;
}

.hasHistory{
    outline: #0863ec solid 2px;
}