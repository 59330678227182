.center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.center-start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.company-map{
    position: relative;
}

.edit-input-label {
    padding-bottom: 5px;
    .edit-key {
        width: 100px;
        text-align: left;
    }
    span{
        padding-left: 5px;
        padding-right: 15px;
        font-weight: 600;
    }
    .edit-reason-alert{
        color: red;
    }
    textarea{
        width: 100%;
    }
}

.phonenumber-input {
    input{
        height: 40px;
        border: 1px solid darkgrey;
        border-radius: 4px;
    }
}

.sub-title {
    color: blue;
    font-size: 24px;
    font-weight: 600;
}

.sub-title-middle {
    color: blue;
    font-size: 24px;
    font-weight: 600;
    text-decoration: underline;
}

.auto-complete {
    .ant-input {
        border-radius: 25px;
    }
}

.play-button {
    border: none;
    margin-right: 2px;
}

.calendar-container {
    width: 450px;
    margin: 0 auto;
    /* Center the calendar if needed */
    padding: 20px 5px;
}
.marker-label{
    font-size: 22px;
    font-weight: 900;
    color: white;
}

@media (max-width: 500px) {
    .ant-picker-panel-container .ant-picker-panels {
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
        flex-wrap: wrap!important;
        direction: ltr;
    }
    .ant-picker-panel {
        display: block!important;
    }
}

.tablink-cover{
    display: flex;
    padding-bottom: 10px;
    .selected{
        background-color: #444;
    }
}
.tablink{
    background-color: #666;
    color: white;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    font-size: 17px;
    width: 25%;
    margin-right: 5px;
    border-radius: 8px;
    margin-left: 5px;
}
.tablink:hover {
    background-color: #777;
  }

.report-item{
    label{
        width: 150px;
    }
}

.report-cover{
    .sub-title {
        color: black;
        font-size: 24px;
        font-weight: 600;
    }
}