.location-text-value{

    width: 250px;
}
.location-text-input{
    height: 38px;
    border-radius: 5px;
    border: 1px solid darkgrey;
    width: 100%;
}
.pd-20{
    padding: 20px;
}
.saveButton{
    background-color: dodgerblue;
    width: 150px;
    height: 40px;
    border-radius: 6px;
    font-size: 16px;
}
.form-control{
    border: 1px solid darkgrey;
}
