.part-icon{
    font-size: 40px;
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    color: white;
}
.back-border{
    // background-color: green;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
}
.part-content{
    text-align: center;
    font-size: 15px;
    color: white;
    align-self: flex-end;
}
.part-content{.number{
    font-size: 30px;
}}