.panel-group {
    margin-top: 30px;
  }
  
  
  .panel-collapse {
    max-height: 500px;
    overflow: hidden;
    transition: max-height .5s ease;
    ul>li.list-group-item {
        position: relative;
        display: block;
        padding: 0.1rem 0.5rem;
        margin-bottom: -1px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
        cursor: pointer;
  }}
  .panel-close {
    max-height: 0;
  }
  .panel-title{
    cursor: pointer;
    text-align: center;
  }
  .add-button{
      margin-top: 10px;
      margin-right: 10px;
      text-align: right;
  }
  .auto-complete>div:nth-child(1){
    z-index: 2;
  }
  .table-header{
      justify-content: space-between;
      padding: 0 20px 10px 20px;
      align-items: center;
      .table-title{
        font-size: 26px;
        color: #1a3ea0;
        font-weight: 900;
      }
  }
  .main-title{
    font-size: 26px;
    color: #1a3ea0;
    font-weight: 900;
  }